.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 23rem);
  &__title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center its children horizontally */
  text-align: center; /* Center text within the container */

  &__image {
    width: 12rem !important;
    height: 12rem !important;
    
    &--title {
      font-weight: 600;
      font-size: 2rem;
    }
  }
}