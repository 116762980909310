.settings-layout {
  // height: calc(100vh - 150px);
  // height: calc(100vh - 16.3vh);
  height: calc(100vh - 300px);

  &__wrapper {
    // display: flex;
    // height: 100%;
    display: flex;
    height: calc(100vh - 144px);
  }
  &__body {
    // position: relative;
    width: 100%;
    background: #f3f5f7;
    padding: 2.1rem 3rem 3.6rem 3rem;
    // padding: 2.1rem 3rem 2.1rem 3rem;
  }
}

// .global-layout {
//   height: 100vh;

//   &__body {
//     display: flex;
//     height: calc(100vh - 74px);
//     &--body {
//       width: 100%;
//       background: #f3f5f7;
//       padding: 2.1rem 3rem 3.6rem 3rem;
//     }
//   }
// }
