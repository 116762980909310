@mixin mq($size) {
	@if $size == phone-only {
		@media (max-width: 599px) {
			@content;
		}
	} @else if $size == tablet-portrait {
		@media (max-width: 600px) {
			@content;
		}
	} @else if $size == tablet-landscape {
		@media (max-width: 900px) {
			@content;
		}
	} @else if $size == desktop {
		@media (max-width: 1500px) {
			@content;
		}
	} @else if $size == mid-desktop {
		@media (max-width: 1600px) {
			@content;
		}
	} @else if $size == big-desktop {
		@media (max-width: 1800px) {
			@content;
		}
	}
}
