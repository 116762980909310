.integration-model_width .ant-modal-close {
    margin-top: 7px !important;
    margin-right: 4px !important;

}


.integration--tag-space {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.svg-space {
    margin-left: 7px !important;
}
.view-docs{
    padding-left: 14px !important;
}

.second_tag{
    margin-top: 3.5px !important;
}