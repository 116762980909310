.search-filter {
	&__wrapper {
		padding: 2rem;
	}
	&__search {
		width: 350px !important;
	}
		&__select {
		width: 400px !important;
	}
}
