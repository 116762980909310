.loader {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	&--svg {
		height: 50px;
		width: 50px;
	}
}
